import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

import {
  Loading,
  NotFoundPage,
  UnavailablePage,
} from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// APPLICATION PIPELINE
const AllApplications = lazyWithRetry(() => import('../components/application-pipeline/AllApplications'));
const StartNewApplication = lazyWithRetry(() => import('../components/application-pipeline/StartNewApplication'));
const ViewApplication = lazyWithRetry(() => import('../components/application-pipeline/ViewApplication'));

// BORROWER PIPELINE
const AllBorrowers = lazyWithRetry(() => import('../components/borrower-pipeline/AllBorrowers'));
const ViewBorrower = lazyWithRetry(() => import('../components/borrower-pipeline/ViewBorrower'));

// FUNDING SOURCE PIPELINE - FS
const AllFundingSources = lazyWithRetry(() => import('../components/funding-source-pipeline/AllFundingSources'));
const ViewFundingSourceDetails = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/ViewFundingSource'));
const FundingSourcesDetail = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/Details'));
const FundingSourcesInterestRates = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/InterestRates'));
const FundingSourcesLendingPartnerAccess = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/LendingPartnerAccess'));
const FundingSourcePrograms = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/funding-sources/Programs'));
// FUNDING SOURCE PIPELINE - FS - PROGRAM
const ViewFundingSourcePrograms = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/ViewPrograms'));
const FundingSourceProgramsDetails = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/Details'));
const FundingSourceProgramBundle = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/ProgramBundle'));
const FundingSourceInterestRates = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/InterestRates'));
const FundingSourceFeeSchedule = lazyWithRetry(() => import('../components/funding-source-pipeline/tabs/programs/FeeSchedule'));

// REPORTS
const KPICharts = lazyWithRetry(() => import('../components/reports/KPICharts'));
const AllReports = lazyWithRetry(() => import('../components/reports/AllReports'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));

// SYSTEM SETTINGS
const IndexSystemSettings = lazyWithRetry(() => import('../components/settings/system-settings/IndexSystemSettings'));
const OrganizationDetails = lazyWithRetry(() => import('../components/settings/system-settings/organization/OrganizationDetails'));
const Theme = lazyWithRetry(() => import('../components/settings/system-settings/themes/Theme'));
const LockDesk = lazyWithRetry(() => import('../components/settings/system-settings/lock-desk/LockDesk'));
const RateSheets = lazyWithRetry(() => import('../components/settings/system-settings/rate-sheets/RateSheets'));
const RateSheetDetail = lazyWithRetry(() => import('../components/settings/system-settings/rate-sheets/RateSheetDetail'));
const ActivityLogTags = lazyWithRetry(() => import('../components/settings/system-settings/activity-log-tags/ActivityLogTags'));
const Flags = lazyWithRetry(() => import('../components/settings/system-settings/flags/Flags'));
const Checklists = lazyWithRetry(() => import('../components/settings/system-settings/checklists/Checklists'));
const ChecklistBuilder = lazyWithRetry(() => import('../components/settings/system-settings/checklists/forms/ChecklistBuilder'));
const Letters = lazyWithRetry(() => import('../components/settings/system-settings/letters/Letters'));
const ActivityLogTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/ActivityLogTemplates'));
const MessageCenterTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/MessageCenterTemplates'));
const DocumentNoteTemplates = lazyWithRetry(() => import('../components/settings/system-settings/templates/DocumentNoteTemplates'));
const Statuses = lazyWithRetry(() => import('../components/settings/system-settings/statuses/Statuses'));
const CreateUpdateStatus = lazyWithRetry(() => import('../components/settings/system-settings/statuses/CreateUpdateStatus'));
const IndexFormData = lazyWithRetry(() => import('../components/settings/system-settings/form-data/IndexFormData'));
const CensusTracts = lazyWithRetry(() => import('../components/settings/system-settings/form-data/CensusTracts'));
const SeriesTypes = lazyWithRetry(() => import('../components/settings/system-settings/form-data/SeriesTypes'));
const Trustees = lazyWithRetry(() => import('../components/settings/system-settings/form-data/Trustees'));
const Counties = lazyWithRetry(() => import('../components/settings/system-settings/form-data/Counties'));
const CreateUpdateCounty = lazyWithRetry(() => import('../components/settings/system-settings/form-data/CreateUpdateCounty'));
const UserDenialReasons = lazyWithRetry(() => import('../components/settings/system-settings/form-data/UserDenialReasons'));
const UserCancelReasons = lazyWithRetry(() => import('../components/settings/system-settings/form-data/UserCancelReasons'));
const FeePayee = lazyWithRetry(() => import('../components/settings/system-settings/form-data/FeePayee'));
const FeePayor = lazyWithRetry(() => import('../components/settings/system-settings/form-data/FeePayor'));
const ProgramTypes = lazyWithRetry(() => import('../components/settings/system-settings/form-data/ProgramTypes'));
const ZipCodes = lazyWithRetry(() => import('../components/settings/system-settings/form-data/ZipCodes'));
const Milestones = lazyWithRetry(() => import('../components/settings/system-settings/milestones/Milestones'));
const DocumentCategories = lazyWithRetry(() => import('../components/settings/system-settings/document-categories/DocumentCategories'));
const ConditionTemplates = lazyWithRetry(() => import('../components/settings/system-settings/conditions/ConditionTemplates'));
const CreateUpdateConditionTemplate = lazyWithRetry(() => import('../components/settings/system-settings/conditions/CreateUpdateConditionTemplate'));
const AchAuthorization = lazyWithRetry(() => import('../components/settings/system-settings/auto-pay-management/AchAuthorization'));
const SmartDocuments = lazyWithRetry(() => import('../components/settings/system-settings/smart-documents/SmartDocuments'));
const SmartDocumentBuilder = lazyWithRetry(() => import('../components/settings/system-settings/smart-documents/SmartDocumentBuilder'));
const SmartPackages = lazyWithRetry(() => import('../components/settings/system-settings/smart-documents/SmartPackages'));

// FUNDING SOURCES
const IndexFundingSources = lazyWithRetry(() => import('../components/settings/funding-sources/IndexFundingSources'));
const ViewFundingSource = lazyWithRetry(() => import('../components/settings/funding-sources/ViewFundingSource'));
const FundingSourceDetails = lazyWithRetry(() => import('../components/settings/funding-sources/FundingSourceDetails'));
const FSLockDesk = lazyWithRetry(() => import('../components/settings/funding-sources/FSLockDesk'));
const FinancialLedger = lazyWithRetry(() => import('../components/settings/funding-sources/FinancialLedger'));
const FundingSourcesLenderAccess = lazyWithRetry(() => import('../components/settings/funding-sources/FundingSourcesLenderAccess'));
const FundingSourcesServicerAccess = lazyWithRetry(() => import('../components/settings/funding-sources/FundingSourcesServicerAccess'));
const FSInterestRates = lazyWithRetry(() => import('../components/settings/funding-sources/FSInterestRates'));

// PROGRAMS
const IndexPrograms = lazyWithRetry(() => import('../components/settings/programs/IndexPrograms'));
const ViewProgram = lazyWithRetry(() => import('../components/settings/programs/ViewProgram'));
const ProgramDetails = lazyWithRetry(() => import('../components/settings/programs/ProgramDetails'));
const ProgramBundle = lazyWithRetry(() => import('../components/settings/programs/ProgramBundle'));
const InterestRates = lazyWithRetry(() => import('../components/settings/programs/InterestRates'));
const FeeTemplates = lazyWithRetry(() => import('../components/settings/programs/FeeTemplates'));
const ProgramLockDesk = lazyWithRetry(() => import('../components/settings/programs/ProgramLockDesk'));
const ProgramFinancialLedger = lazyWithRetry(() => import('../components/settings/programs/ProgramFinancialLedger'));

// MASTER SERVICERS
const IndexServicers = lazyWithRetry(() => import('../components/settings/servicers/IndexServicers'));
const ViewServicer = lazyWithRetry(() => import('../components/settings/servicers/ViewServicer'));
const ServicerDetails = lazyWithRetry(() => import('../components/settings/servicers/ServicerDetails'));
const ServicersFundingSourceAccess = lazyWithRetry(() => import('../components/settings/servicers/ServicersFundingSourceAccess'));

// LENDERS
const IndexLenders = lazyWithRetry(() => import('../components/settings/lenders/IndexLenders'));
const ViewLender = lazyWithRetry(() => import('../components/settings/lenders/ViewLender'));
const LenderDetails = lazyWithRetry(() => import('../components/settings/lenders/LenderDetails'));
const LendersFundingSourceAccess = lazyWithRetry(() => import('../components/settings/lenders/LendersFundingSourceAccess'));
const LenderUsers = lazyWithRetry(() => import('../components/settings/lenders/LenderUsers'));
const ViewLenderUser = lazyWithRetry(() => import('../components/settings/lenders/ViewLenderUser'));
const InviteLenderUser = lazyWithRetry(() => import('../components/settings/lenders/InviteLenderUser'));
const LenderPermissionGroups = lazyWithRetry(() => import('../components/settings/lenders/LenderPermissionGroups'));
const IndexLenderLocations = lazyWithRetry(() => import('../components/settings/lenders/IndexLenderLocations'));
const PaymentManagement = lazyWithRetry(() => import('../components/settings/lenders/PaymentManagement'));

// USERS
const IndexUsers = lazyWithRetry(() => import('../components/settings/users/IndexUsers'));
const Teams = lazyWithRetry(() => import('../components/settings/users/Teams'));
const TeamUsers = lazyWithRetry(() => import('../components/settings/users/TeamUsers'));
const AllUsers = lazyWithRetry(() => import('../components/settings/users/AllUsers'));
const ViewUser = lazyWithRetry(() => import('../components/settings/users/ViewUser'));
const InviteProcessorUser = lazyWithRetry(() => import('../components/settings/users/InviteProcessorUser'));
const UserPermissionGroups = lazyWithRetry(() => import('../components/settings/users/UserPermissionGroups'));

// HELP
const Help = lazyWithRetry(() => import('../components/settings/help/Help'));

// RELEASE NOTES
const ReleaseNotes = lazyWithRetry(() => import('../components/settings/help/release-notes/ReleaseNotes'));
const ViewReleaseNote = lazyWithRetry(() => import('../components/settings/help/release-notes/ViewReleaseNote'));

// HELP TICKETS
// const HelpTickets = lazyWithRetry(() => import('../components/settings/help/help-tickets/HelpTickets'));
// const CreateHelpTicket = lazyWithRetry(() => import('../components/settings/help/help-tickets/CreateHelpTicket'));
// const ViewHelpTicket = lazyWithRetry(() => import('../components/settings/help/help-tickets/ViewHelpTicket'));
// const HelpTicketDetails = lazyWithRetry(() => import('../components/settings/help/help-tickets/tabs/HelpTicketDetails'));
// const HelpTicketCommunications = lazyWithRetry(() => import('../components/settings/help/help-tickets/tabs/HelpTicketCommunications'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createHistory();


const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login/:organization_uuid' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:organization_uuid/:token_uuid' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session/:organization_uuid' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password/:organization_uuid' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:organization_uuid/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* APPLICATION PIPELINE*/}
              <Route path='/applications' element={<PrivateRoute perform={[
                'processor:application:read-all',
                'processor:application:read-team',
                'processor:application:read-assigned',
              ]}><AllApplications /></PrivateRoute>} />
              <Route path='/applications/start' element={<PrivateRoute perform={['processor:application:write']}><StartNewApplication /></PrivateRoute>} />

              <Route path='/applications/:application_uuid/:layout?' element={<PrivateRoute perform={[
                'processor:application:read-all',
                'processor:application:read-team',
                'processor:application:read-assigned',
              ]}><ViewApplication /></PrivateRoute>} />

              {/* BORROWER PIPELINE*/}
              <Route path='/borrowers' element={<PrivateRoute perform={['processor:borrower:read-all']}><AllBorrowers /></PrivateRoute>} />
              <Route path='/borrowers/:borrower_uuid' element={<PrivateRoute perform={['processor:borrower:read-all']}><ViewBorrower /></PrivateRoute>} />

              {/* FUNDING SOURCE PIPELINE*/}
              <Route path='/funding-sources-pipeline' element={<PrivateRoute perform={['processor:fs-pipeline:read-all']}><AllFundingSources /></PrivateRoute>} />
              <Route path='/funding-sources-pipeline/:funding_source_uuid' element={<PrivateRoute perform={['processor:fs-pipeline:read-all']}><ViewFundingSourceDetails /></PrivateRoute>} >
                <Route path='details' element={<FundingSourcesDetail />} />
                <Route path='interest-rates' element={<FundingSourcesInterestRates />} />
                <Route path='lending-partner-access' element={<FundingSourcesLendingPartnerAccess />} />
                <Route path='programs' element={<FundingSourcePrograms />} />
              </Route>
              <Route path='/funding-sources-pipeline/:funding_source_uuid/programs/:program_uuid' element={<PrivateRoute perform={['processor:fs-pipeline:read-all']}><ViewFundingSourcePrograms /></PrivateRoute>}>
                <Route path='details' element={<FundingSourceProgramsDetails />} />
                <Route path='program-bundle' element={<FundingSourceProgramBundle />} />
                <Route path='interest-rates' element={<FundingSourceInterestRates />} />
                <Route path='fee-templates' element={<FundingSourceFeeSchedule />} />
              </Route>

              {/* REPORTS */}
              <Route path='/reports' element={<PrivateRoute perform={['processor:report:read-all-reports']}><AllReports /></PrivateRoute>} />
              <Route path='/charts' element={<PrivateRoute perform={['processor:report:read-kpi-charts']}><KPICharts /></PrivateRoute>} />

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>

              {/* SYSTEM SETTINGS */}
              <Route path='system-settings'>
                <Route index element={<PrivateRoute perform={[
                  'processor:settings-organization:read',
                  'processor:settings-theme:read',
                  'processor:settings-lock-desk:read',
                  'processor:settings-rate-sheets:read',
                  'processor:settings-communication-templates:read',
                  'processor:settings-activity-log-note-templates:read',
                  'processor:settings-document-note-templates:read',
                  'processor:settings-milestones:read',
                  'processor:settings-statuses:read',
                  'processor:settings-flags:read',
                  'processor:settings-letters:read',
                  'processor:settings-checklist-templates:read',
                  'processor:settings-condition-templates:read',
                  'processor:settings-document-categories:read',
                  'processor:settings-form-data:read',
                  'processor:settings-ach-authorization:read',
                  'processor:settings-activity-log-tags:read',
                ]}><IndexSystemSettings /></PrivateRoute>} />
                <Route path='organization' element={<PrivateRoute perform={['processor:settings-organization:read']}><OrganizationDetails /></PrivateRoute>} />
                <Route path='theme' element={<PrivateRoute perform={['processor:settings-theme:read']}><Theme /></PrivateRoute>} />
                <Route path='lock-desk' element={<PrivateRoute perform={['processor:settings-lock-desk:read']}><LockDesk /></PrivateRoute>} />
                <Route path='rate-sheets' element={<PrivateRoute perform={['processor:settings-rate-sheets:read']}><RateSheets /></PrivateRoute>} />
                <Route path='rate-sheets/:rate_sheet_uuid' element={<PrivateRoute perform={['processor:settings-rate-sheets:read']}><RateSheetDetail /></PrivateRoute>} />
                <Route path='message-center-templates' element={<PrivateRoute perform={['processor:settings-communication-templates:read']}><MessageCenterTemplates /></PrivateRoute>} />
                <Route path='activity-log-templates' element={<PrivateRoute perform={['processor:settings-activity-log-note-templates:read']}><ActivityLogTemplates /></PrivateRoute>} />
                <Route path='document-note-templates' element={<PrivateRoute perform={['processor:settings-document-note-templates:read']}><DocumentNoteTemplates /></PrivateRoute>} />
                <Route path='milestones' element={<PrivateRoute perform={['processor:settings-milestones:read']}><Milestones /></PrivateRoute>} />
                <Route path='statuses' element={<PrivateRoute perform={['processor:settings-statuses:read']}><Statuses /></PrivateRoute>} />
                <Route path='statuses/create' element={<PrivateRoute perform={['processor:settings-statuses:write']}><CreateUpdateStatus /></PrivateRoute>} />
                <Route path='statuses/edit/:processor_status_uuid' element={<PrivateRoute perform={['processor:settings-statuses:write']}><CreateUpdateStatus /></PrivateRoute>} />
                <Route path='flags' element={<PrivateRoute perform={['processor:settings-flags:read']}><Flags /></PrivateRoute>} />
                <Route path='letters' element={<PrivateRoute perform={['processor:settings-letters:read']}><Letters /></PrivateRoute>} />
                <Route path='checklists' element={<PrivateRoute perform={['processor:settings-checklist-templates:read']}><Checklists /></PrivateRoute>} />
                <Route path='checklists/:checklist_uuid' element={<PrivateRoute perform={['processor:settings-checklist-templates:read']}><ChecklistBuilder /></PrivateRoute>} />
                <Route path='conditions' element={<PrivateRoute perform={['processor:settings-condition-templates:read']}><ConditionTemplates /></PrivateRoute>} />
                <Route path='conditions/create' element={<PrivateRoute perform={['processor:settings-condition-templates:write']}><CreateUpdateConditionTemplate /></PrivateRoute>} />
                <Route path='conditions/edit/:condition_template_uuid' element={<PrivateRoute perform={['processor:settings-condition-templates:write']}><CreateUpdateConditionTemplate /></PrivateRoute>} />
                <Route path='document-categories' element={<PrivateRoute perform={['processor:settings-document-categories:read']}><DocumentCategories /></PrivateRoute>} />
                <Route path='form-data' element={<PrivateRoute perform={['processor:settings-form-data:read']}><IndexFormData /></PrivateRoute>} />
                <Route path='form-data/census-tracts' element={<PrivateRoute perform={['processor:settings-form-data:read']}><CensusTracts /></PrivateRoute>} />
                <Route path='form-data/counties' element={<PrivateRoute perform={['processor:settings-form-data:read']}><Counties /></PrivateRoute>} />
                <Route path='form-data/counties/create' element={<PrivateRoute perform={['processor:settings-form-data:write']}><CreateUpdateCounty /></PrivateRoute>} />
                <Route path='form-data/counties/edit/:county_uuid' element={<PrivateRoute perform={['processor:settings-form-data:write']}><CreateUpdateCounty /></PrivateRoute>} />
                <Route path='form-data/user-denial-reasons' element={<PrivateRoute perform={['processor:settings-form-data:read']}><UserDenialReasons /></PrivateRoute>} />
                <Route path='form-data/user-cancel-reasons' element={<PrivateRoute perform={['processor:settings-form-data:read']}><UserCancelReasons /></PrivateRoute>} />
                <Route path='form-data/fee-payees' element={<PrivateRoute perform={['processor:settings-form-data:read']}><FeePayee /></PrivateRoute>} />
                <Route path='form-data/fee-payors' element={<PrivateRoute perform={['processor:settings-form-data:read']}><FeePayor /></PrivateRoute>} />
                <Route path='form-data/program-types' element={<PrivateRoute perform={['processor:settings-form-data:read']}><ProgramTypes /></PrivateRoute>} />
                <Route path='form-data/zip-codes' element={<PrivateRoute perform={['processor:settings-form-data:read']}><ZipCodes /></PrivateRoute>} />
                <Route path='form-data/series-types' element={<PrivateRoute perform={['processor:settings-form-data:read']}><SeriesTypes /></PrivateRoute>} />
                <Route path='form-data/trustees' element={<PrivateRoute perform={['processor:settings-form-data:read']}><Trustees /></PrivateRoute>} />
                <Route path='auto-pay/ach-authorization-form' element={<PrivateRoute perform={['processor:settings-ach-authorization:read']}><AchAuthorization /></PrivateRoute>} />
                <Route path='activity-log-tags' element={<PrivateRoute perform={['processor:settings-activity-log-tags:read']}><ActivityLogTags /></PrivateRoute>} />
                <Route path='smart-documents' element={<PrivateRoute perform={['processor:settings-smart-docs:read']}><SmartDocuments /></PrivateRoute>} />
                <Route path='smart-documents/:smart_document_uuid' element={<PrivateRoute perform={['processor:settings-smart-docs:read']}><SmartDocumentBuilder /></PrivateRoute>} />
                <Route path='smart-packages' element={<PrivateRoute perform={['processor:settings-smart-docs:read']}><SmartPackages /></PrivateRoute>} />
              </Route>

              {/* FUNDING SOURCES */}
              <Route path='funding-sources'>
                <Route index element={<PrivateRoute perform={['processor:funding-source-settings:read']}><IndexFundingSources /></PrivateRoute>} />
                <Route path=':funding_source_uuid' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ViewFundingSource /></PrivateRoute>} />
                <Route path=':funding_source_uuid/details' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FundingSourceDetails /></PrivateRoute>} />
                <Route path=':funding_source_uuid/lock-desk' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FSLockDesk /></PrivateRoute>} />
                <Route path=':funding_source_uuid/financial-ledger' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FinancialLedger /></PrivateRoute>} />
                <Route path=':funding_source_uuid/lender-access' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FundingSourcesLenderAccess /></PrivateRoute>} />
                <Route path=':funding_source_uuid/servicer-access' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FundingSourcesServicerAccess /></PrivateRoute>} />
                <Route path=':funding_source_uuid/rates' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FSInterestRates /></PrivateRoute>} />

                {/* PROGRAMS */}
                <Route path=':funding_source_uuid/programs'>
                  <Route index element={<PrivateRoute perform={['processor:funding-source-settings:read']}><IndexPrograms /></PrivateRoute>} />
                  <Route path=':program_uuid' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ViewProgram /></PrivateRoute>} />
                  <Route path=':program_uuid/details' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ProgramDetails /></PrivateRoute>} />
                  <Route path=':program_uuid/program-bundle' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ProgramBundle /></PrivateRoute>} />
                  <Route path=':program_uuid/rates' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><InterestRates /></PrivateRoute>} />
                  <Route path=':program_uuid/fee-templates' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><FeeTemplates /></PrivateRoute>} />
                  <Route path=':program_uuid/lock-desk' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ProgramLockDesk /></PrivateRoute>} />
                  <Route path=':program_uuid/financial-ledger' element={<PrivateRoute perform={['processor:funding-source-settings:read']}><ProgramFinancialLedger /></PrivateRoute>} />
                </Route>
              </Route>

              {/* MASTER SERVICERS */}
              <Route path='servicers'>
                <Route index element={<PrivateRoute perform={['processor:master-servicer-settings:read']}><IndexServicers /></PrivateRoute>} />
                <Route path=':servicer_uuid' element={<PrivateRoute perform={['processor:master-servicer-settings:read']}><ViewServicer /></PrivateRoute>} />
                <Route path=':servicer_uuid/details' element={<PrivateRoute perform={['processor:master-servicer-settings:read']}><ServicerDetails /></PrivateRoute>} />
                <Route path=':servicer_uuid/funding-sources' element={<PrivateRoute perform={['processor:master-servicer-settings:read']}><ServicersFundingSourceAccess /></PrivateRoute>} />
              </Route>

              {/* LENDERS */}
              <Route path='lenders'>
                <Route index element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><IndexLenders /></PrivateRoute>} />
                <Route path=':lender_uuid' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><ViewLender /></PrivateRoute>} />
                <Route path=':lender_uuid/details' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><LenderDetails /></PrivateRoute>} />
                <Route path=':lender_uuid/payment-management' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><PaymentManagement /></PrivateRoute>} />
                <Route path=':lender_uuid/funding-source-associations' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><LendersFundingSourceAccess /></PrivateRoute>} />
                <Route path=':lender_uuid/users' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><LenderUsers /></PrivateRoute>} />
                <Route path=':lender_uuid/users/:lender_user_uuid' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><ViewLenderUser /></PrivateRoute>} />
                <Route path=':lender_uuid/users/invite' element={<PrivateRoute perform={['processor:lending-partner-settings:write']}><InviteLenderUser /></PrivateRoute>} />
                <Route path=':lender_uuid/permission-groups' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><LenderPermissionGroups /></PrivateRoute>} />
                <Route path=':lender_uuid/locations' element={<PrivateRoute perform={['processor:lending-partner-settings:read']}><IndexLenderLocations /></PrivateRoute>} />
              </Route>

              {/* USERS */}
              <Route path='users'>
                <Route index element={<PrivateRoute perform={['processor:user-settings:read']}><IndexUsers /></PrivateRoute>} />
                <Route path='teams' element={<PrivateRoute perform={['processor:user-settings:read']}><Teams /></PrivateRoute>} />
                <Route path='teams/:team_uuid' element={<PrivateRoute perform={['processor:user-settings:read']}><TeamUsers /></PrivateRoute>} />
                <Route path='all-users' element={<PrivateRoute perform={['processor:user-settings:read']}><AllUsers /></PrivateRoute>} />
                <Route path='all-users/:user_processor_uuid' element={<PrivateRoute perform={['processor:user-settings:read']}><ViewUser /></PrivateRoute>} />
                <Route path='invite-user' element={<PrivateRoute perform={['processor:user-settings:write']}><InviteProcessorUser /></PrivateRoute>} />
                <Route path='user-permissions' element={<PrivateRoute perform={['processor:user-settings:read']}><UserPermissionGroups /></PrivateRoute>} />
              </Route>

              {/* HELP */}
              <Route path='help'>
                <Route index element={<Help />} />

                {/* RELEASE NOTES */}
                <Route path='release-notes' element={<PrivateRoute perform={['processor:release-notes:read']}><ReleaseNotes /></PrivateRoute>} />
                <Route path='release-notes/:release_note_uuid' element={<PrivateRoute perform={['processor:release-notes:read']}><ViewReleaseNote /></PrivateRoute>} />

                {/* HELP TICKETS */}
                {/* <Route path='help-tickets' element={<PrivateRoute><HelpTickets /></PrivateRoute>} />
                <Route path='help-tickets/new' element={<PrivateRoute perform='processor:dummy:all'><CreateHelpTicket /></PrivateRoute>} />
                <Route path='help-tickets/:help_ticket_uuid' element={<PrivateRoute><ViewHelpTicket /></PrivateRoute>}>
                  <Route path='details' element={<HelpTicketDetails />} />
                  <Route path='communications' element={<HelpTicketCommunications />} />
                </Route> */}
              </Route>

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<UnavailablePage />} />

              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
        {environment !== 'production' && <div className='position-absolute float-end end-0'>Version {VERSION}</div>}
      </div>
    </ScrollToTop>
  </CustomRouter>
);

AppRouter.propTypes = {
};

export default AppRouter;
